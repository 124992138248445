jQuery(document).ready(function($) {
	// Mobile menu
	$(".mobile-navigation-menu").mmenu({
		offCanvas: {
			position: "right"
		},
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	$(".mobile-menu-button").click(function() {
		if ($( ".mobile-navigation-menu" ).hasClass( "mm-opened" )) {
			$(".mobile-navigation-menu").data( "mmenu" ).close();
		}
		else {
			$(".mobile-navigation-menu").data( "mmenu" ).open();
		}
	});

	// Fit Vids
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-body-section").fitVids({ ignore: "nofit" });
		$(".homeboxes").fitVids({ ignore: "nofit" });
	}

	// Sticky Header
	$(window).scroll(function() {
		$('.header-section').toggleClass("sticky", $(this).scrollTop() > 1);
	});


	// Accessible menu
	$(".nav-drop-toggle:has('.container-wrapper')").addClass("has-dropdown");
	$(".nav-link.active-item").parent(".nav-drop-toggle").addClass("active-li");

	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".has-dropdown > .nav-link").click(function(event) {
			if ( !$(this).parents(".has-dropdown").hasClass("open") ) {
				$(".nav-drop-toggle").removeClass("open");
				event.preventDefault();
				$(this).parent(".has-dropdown").addClass("open");
			}
		});
	}
	else {
		$(".nav-drop-toggle").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	$(".desktop-navigation-menu").accessibleMenu();

	// Webflow: Interactions: Init
	Webflow.require('ix').init([
		{slug: "dd-widget-smooth-reveal",name: "DD Widget Smooth Reveal",value: {style: {},triggers: [{type: "dropdown",stepsA: [{opacity: 0,height: "0px"},{opacity: 1,height: "auto",transition: "opacity 200 ease 0, height 200 ease 0"}],stepsB: [{height: "0px",transition: "height 100ms ease 0"}]}]}},
		{slug: "fade-in-bottom-page-loads",name: "Fade in bottom (page loads)",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "load",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "fade-in-left-scroll-in",name: "Fade in left (scroll in)",value: {style: {opacity: 0,x: "-50px",y: "0px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "fade-in-right-scroll-in",name: "Fade in right (scroll in)",value: {style: {opacity: 0,x: "50px",y: "0px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "fade-in-top-scroll-in",name: "Fade in top (scroll in)",value: {style: {opacity: 0,x: "0px",y: "-50px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "fade-in-bottom-scroll-in",name: "Fade in bottom (scroll in)",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "bounce-in-scroll-in",name: "Bounce in (scroll in)",value: {style: {opacity: 0,scaleX: 0.6000000000000006,scaleY: 0.6000000000000006,scaleZ: 1},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 600ms ease 0ms, opacity 600ms ease 0ms",scaleX: 1.08,scaleY: 1.08,scaleZ: 1},{transition: "transform 150ms ease-out-cubic 0ms",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: []}]}},
		{slug: "scale-on-scroll",name: "Scale on Scroll",value: {style: {},triggers: [{type: "scroll",offsetTop: "20%",stepsA: [],stepsB: [{wait: "200ms",width: "250px",transition: "transform 200 ease 0, width 200 ease 0"}]}]}},
		{slug: "new-interaction",name: "New Interaction",value: {style: {},triggers: []}},
		{slug: "search-dropdown",name: "Search Dropdown",value: {style: {},triggers: [{type: "click",selector: ".header-search-container",stepsA: [{display: "block",height: "auto",transition: "height 300ms ease 0"}],stepsB: [{height: "0px",transition: "height 300ms ease 0"}]}]}},
		{slug: "contact-form-reveal",name: "Contact Form Reveal",value: {style: {},triggers: [{type: "click",selector: ".form-reveal-section",stepsA: [{wait: "200ms",display: "block",transition: "transform 200 ease 0"}],stepsB: [{wait: "200ms",display: "none"}]}]}},
		{slug: "new-interaction-2",name: "New Interaction 2",value: {style: {},triggers: []}},
		{slug: "header-scroll-srink",name: "Header Scroll Srink",value: {style: {width: "320px"},triggers: [{type: "scroll",stepsA: [],stepsB: [{wait: "200ms",width: "250px",transition: "width 200 ease 0"}]}]}},
		{slug: "show-on-scroll",name: "Show on scroll",value: {style: {display: "none"},triggers: [{type: "scroll",stepsA: [{wait: "200ms",display: "block"}],stepsB: []}]}},
		{slug: "start-video",name: "Start Video",value: {style: {},triggers: [{type: "click",selector: ".video-image-placeholder-fake",stepsA: [{opacity: 0,transition: "opacity 200 ease 0"}],stepsB: []}]}}
	]);
});
